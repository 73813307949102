const navigation = {
  support: [
    {
      name: 'Press package',
      href: 'https://drive.google.com/drive/folders/1BwEQmn_I9-xFzjiJdLy9d8JJnkya5Gb4',
    },
  ],
  legal: [{ name: 'Policy', href: 'https://darkmatterlabs.org/Privacy-Policy' }],
  social: [
    {
      name: 'Twitter',
      href: 'https://twitter.com/Trees_AI',
      icon: (props) => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path d='M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84' />
        </svg>
      ),
    },
    {
      name: 'GitHub',
      href: 'https://github.com/Dark-Matter-Labs',
      icon: (props) => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path
            fillRule='evenodd'
            d='M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z'
            clipRule='evenodd'
          />
        </svg>
      ),
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/trees-ai',
      icon: (props) => (
        <svg
          role='img'
          fill='currentColor'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
          {...props}
        >
          <path d='M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z' />
        </svg>
      ),
    },
  ],
};

export default function Footer() {
  return (
    <footer className='rounded-t-[60px] bg-dark-wood-800 ' aria-labelledby='footer-heading'>
      <h2 id='footer-heading' className='sr-only'>
        Footer
      </h2>
      <div className='mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
        <div className='grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4 sm:place-content-around'>
          <div className='md:grid md:grid-cols-2 md:gap-8'>
            <div className='mt-12 md:mt-0 flex'>
              <img
                className='h-8 w-auto pr-4'
                src='assets/TreesAI_logo.svg'
                alt='TreesAI Impact Planner logo'
              />
            </div>
          </div>
          <div className='sm:grid sm:grid-cols-2 '>
            <div className='place-self-end'>
              <ul className='mt-4 space-y-4'>
                {navigation.support.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className='book-info-md text-dark-wood-300 hover:text-white'
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className='mt-12 md:mt-0 place-self-end'>
              <ul className='mt-4 space-y-4'>
                {navigation.legal.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className='book-info-md text-dark-wood-300 hover:text-white'
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className='mt-8 border-t border-dark-wood-300 pt-8 grid grid-cols-1 sm:grid-cols-4 gap-8 sm:place-content-around'>
          <div className='sm:col-span-2'>
            <p className='mt-8 book-info-md text-dark-wood-300 md:order-1 md:mt-0'>
              Trees as Infrastructure, part of{' '}
              <a target='_blank' rel='noopener noreferrer' href='https://darkmatterlabs.org/'>
                Dark Matter Labs
              </a>
            </p>
            <p className='mt-8 book-info-md text-dark-wood-300 md:order-1 md:mt-0'>
              Co-development partners:{' '}
              <a target='_blank' rel='noopener noreferrer' href='https://lucidminds.ai/'>
                Lucidminds
              </a>{' '}
              &{' '}
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.politicsfortomorrow.eu/'
              >
                Politics for Tomorrow
              </a>
            </p>
          </div>
          <div className='flex space-x-6'>
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className='text-dark-wood-300 hover:text-gray-300'
              >
                <span className='sr-only'>{item.name}</span>
                <item.icon className='h-6 w-6' aria-hidden='true' />
              </a>
            ))}
          </div>
          <div className='sm:place-self-end'>
            <p className='mt-8 book-info-md text-dark-wood-300 md:mt-0'>
              Typeface by{' '}
              <a href='https://abcdinamo.com/' target='_blank' rel='noopener noreferrer'>
                Dinamo
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
