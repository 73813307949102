export default function Privacy() {
  return (
    <div className='relative bg-gray' id='contact'>
      <div className='relative max-w-7xl mx-auto lg:grid lg:grid-cols-2'>
        <div className='bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12'>
          <div className=''>
            <h2 className='text-2xl font-bold text-gray-900 sm:text-3xl'>Privacy Policy</h2>
            <p className='mt-3 text-lg leading-6 text-gray-500'>
              At TreesAI, accessible from https://treesasinfrastructure.com/, one of our main
              priorities is the privacy of our visitors. This Privacy Policy document contains types
              of information that is collected and recorded by TreesAI and how we use it.
            </p>

            <p className='mt-3 text-lg leading-6 text-gray-500'>
              If you have additional questions or require more information about our Privacy Policy,
              do not hesitate to contact us at treesai@darkmatterlabs.org or info@darkmatterlabs.org
            </p>

            <p className='mt-3 text-lg leading-6 text-gray-500'>
              This Privacy Policy applies only to our online activities and is valid for visitors to
              our website with regards to the information that they shared and/or collect in
              TreesAI. This policy is not applicable to any information collected offline or via
              channels other than this website.
            </p>
            <div className='pt-5 pb-5'>
              <img
                src='https://simpleanalyticsbadge.com/treesasinfrastructure.com'
                loading='lazy'
                referrerpolicy='no-referrer'
                crossorigin='anonymous'
                alt='privacy centric analytics badge'
              />
            </div>

            <h3 className='mt-4 text-xl font-semibold text-gray-900 sm:text-2xl'>Consent</h3>

            <p className='mt-3 text-lg leading-6 text-gray-500'>
              By using our website, you hereby consent to our Privacy Policy and agree to its terms.
            </p>

            <h3 className='mt-4 text-xl font-semibold text-gray-900 sm:text-2xl'>
              Information we collect
            </h3>

            <p className='mt-3 text-lg leading-6 text-gray-500'>
              The personal information that you are asked to provide, and the reasons why you are
              asked to provide it, will be made clear to you at the point we ask you to provide your
              personal information.
            </p>
            <p className='mt-3 text-lg leading-6 text-gray-500'>
              If you contact us directly, we may receive additional information about you such as
              your name, email address, phone number, the contents of the message and/or attachments
              you may send us, and any other information you may choose to provide.
            </p>

            <h3 className='mt-4 text-xl font-semibold text-gray-900 sm:text-2xl'>
              How we use your information
            </h3>

            <p className='mt-3 text-lg leading-6 text-gray-500'>
              We use the information we collect in various ways, including to:
            </p>

            <ul className='mt-3 text-lg leading-6 text-gray-500'>
              <li>- Provide, operate, and maintain our website</li>
              <li>- Improve, personalize, and expand our website</li>
              <li>- Understand and analyze how you use our website</li>
              <li>- Develop new products, services, features, and functionality</li>
              <li>
                - Communicate with you, either directly or through one of our partners, including
                for customer service, to provide you with updates and other information relating to
                the website, and for marketing and promotional purposes
              </li>
              <li>- Send you emails</li>
              <li>- Find and prevent fraud</li>
            </ul>

            <h3 className='mt-4 text-xl font-semibold text-gray-900 sm:text-2xl'>Log Files</h3>

            <p className='mt-3 text-lg leading-6 text-gray-500'>
              TreesAI follows a standard procedure of using log files. These files log visitors when
              they visit websites. All hosting companies do this and a part of hosting services'
              analytics. The information collected by log files include internet protocol (IP)
              addresses, browser type, Internet Service Provider (ISP), date and time stamp,
              referring/exit pages, and possibly the number of clicks. These are not linked to any
              information that is personally identifiable. The purpose of the information is for
              analyzing trends, administering the site, tracking users' movement on the website, and
              gathering demographic information.
            </p>

            <h3>Cookies and Web Beacons</h3>

            <p className='mt-3 text-lg leading-6 text-gray-500'>
              Like any other website, TreesAI uses 'cookies'. These cookies are used to store
              information including visitors' preferences, and the pages on the website that the
              visitor accessed or visited. The information is used to optimize the users' experience
              by customizing our web page content based on visitors' browser type and/or other
              information.
            </p>

            <h3 className='mt-4 text-xl font-semibold text-gray-900 sm:text-2xl'>
              CCPA Privacy Rights (Do Not Sell My Personal Information)
            </h3>

            <p className='mt-3 text-lg leading-6 text-gray-500'>
              Under the CCPA, among other rights, California consumers have the right to:
            </p>
            <p className='mt-3 text-lg leading-6 text-gray-500'>
              Request that a business that collects a consumer's personal data disclose the
              categories and specific pieces of personal data that a business has collected about
              consumers.
            </p>
            <p className='mt-3 text-lg leading-6 text-gray-500'>
              Request that a business delete any personal data about the consumer that a business
              has collected.
            </p>
            <p className='mt-3 text-lg leading-6 text-gray-500'>
              Request that a business that sells a consumer's personal data, not sell the consumer's
              personal data.
            </p>
            <p className='mt-3 text-lg leading-6 text-gray-500'>
              If you make a request, we have one month to respond to you. If you would like to
              exercise any of these rights, please contact us.
            </p>

            <h3 className='mt-4 text-xl font-semibold text-gray-900 sm:text-2xl'>
              GDPR Data Protection Rights
            </h3>

            <p className='mt-3 text-lg leading-6 text-gray-500'>
              We would like to make sure you are fully aware of all of your data protection rights.
              Every user is entitled to the following:
            </p>
            <p className='mt-3 text-lg leading-6 text-gray-500'>
              The right to access – You have the right to request copies of your personal data. We
              may charge you a small fee for this service.
            </p>
            <p className='mt-3 text-lg leading-6 text-gray-500'>
              The right to rectification – You have the right to request that we correct any
              information you believe is inaccurate. You also have the right to request that we
              complete the information you believe is incomplete.
            </p>
            <p className='mt-3 text-lg leading-6 text-gray-500'>
              The right to erasure – You have the right to request that we erase your personal data,
              under certain conditions.
            </p>
            <p className='mt-3 text-lg leading-6 text-gray-500'>
              The right to restrict processing – You have the right to request that we restrict the
              processing of your personal data, under certain conditions.
            </p>
            <p className='mt-3 text-lg leading-6 text-gray-500'>
              The right to object to processing – You have the right to object to our processing of
              your personal data, under certain conditions.
            </p>
            <p className='mt-3 text-lg leading-6 text-gray-500'>
              The right to data portability – You have the right to request that we transfer the
              data that we have collected to another organization, or directly to you, under certain
              conditions.
            </p>
            <p className='mt-3 text-lg leading-6 text-gray-500'>
              If you make a request, we have one month to respond to you. If you would like to
              exercise any of these rights, please contact us.
            </p>

            <h3 className='mt-4 text-xl font-semibold text-gray-900 sm:text-2xl'>
              Children's Information
            </h3>

            <p className='mt-3 text-lg leading-6 text-gray-500'>
              Another part of our priority is adding protection for children while using the
              internet. We encourage parents and guardians to observe, participate in, and/or
              monitor and guide their online activity.
            </p>

            <p className='mt-3 text-lg leading-6 text-gray-500'>
              TreesAI does not knowingly collect any Personal Identifiable Information from children
              under the age of 13. If you think that your child provided this kind of information on
              our website, we strongly encourage you to contact us immediately and we will do our
              best efforts to promptly remove such information from our records.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
